import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import { PublicAvailability } from "practicare/types//publicAvailability.model";
import type { PublicLocation } from "practicare/types/location.model";
import type { PublicTenantConfig } from "practicare/types/tenant.model";
import type { PublicUser } from "practicare/types/user.model";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ShowMoreText from "react-show-more-text";
import { LocationIcon } from "src/utils/LocationIcon";
import styled from "styled-components";
import { formatCurrency } from "../config/utils";
import { SwiperComponentUnix } from "./SwiperComponentUnix";

interface UserCardProps {
  availability: PublicAvailability;
  user: PublicUser;
  locations: PublicLocation[];
  tenantData?: PublicTenantConfig;
  selectedLocationId: string;
  selectedServiceId: string;
  widgetLocationId: string | null;
  isSingleUserView: boolean;
  calendarOnly: boolean;
  isCompactView: boolean;
}

const StyledShowMoreText = styled(ShowMoreText)`
  .show-more-less-clickable {
    /* font-family: "Red Hat Display" !important; */
    font-weight: 500;
  }
  span {
    color: #1c2f4b !important;
    /* font-family: "Red Hat Display" !important; */
    font-weight: 400 !important;
    font-size: 14 !important;
  }
`;
const UserCard: React.FC<UserCardProps> = ({
  availability,
  user,
  locations,
  tenantData,
  widgetLocationId,
  selectedLocationId,
  selectedServiceId,
  isSingleUserView,
  calendarOnly,
  isCompactView,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const userLocationsNames = React.useMemo(() => {
    const locationNames = availability.locationIds
      .filter((l) => (widgetLocationId ? l === widgetLocationId : true))
      .map((lid) => {
        const locationObject = locations.find((l) => l.id === lid) || {
          name: "",
          isOnline: false,
        };
        if (locationObject.isOnline) {
          if (tenantData?.widget.addOnlineToLocations) {
            return "";
          } else {
            return "Online";
          }
        }
        return locationObject.name;
      })
      .filter((t) => t !== "")
      .sort((a, b) => a.localeCompare(b));

    if (tenantData?.widget.addOnlineToLocations) {
      locationNames.push("Online");
    }

    return locationNames;
  }, [
    availability.locationIds,
    locations,
    tenantData?.widget.addOnlineToLocations,
  ]);

  const priceForService = React.useMemo(() => {
    if (selectedServiceId !== "") {
      const serviceId = selectedServiceId.split("-")[0];
      const isServiceOnline = selectedServiceId.includes("-online");
      const selectedService = availability.userAvailableServices
        .filter(
          (uas) =>
            uas.serviceId === serviceId &&
            (isServiceOnline ? uas.isOnline : !uas.isOnline)
        )
        .map((uas) => uas.price);
      const minPrice = Math.min(...selectedService);
      const maxPrice = Math.max(...selectedService);
      return [minPrice, maxPrice];
    } else {
      const prices = availability.userAvailableServices.map((uas) => uas.price);
      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);
      return [minPrice, maxPrice];
    }
  }, [selectedServiceId, availability.userAvailableServices]);

  const priceLabel = React.useMemo(() => {
    if (priceForService[0] === priceForService[1]) {
      return (
        (selectedServiceId
          ? t("Price for selected service")
          : t("Price for services")) +
        ": " +
        formatCurrency(priceForService[0], tenantData?.defaultCurrency || "PLN")
      );
    } else {
      return (
        (selectedServiceId
          ? t("Price range for selected service")
          : t("Price range")) +
        ": " +
        formatCurrency(
          priceForService[0],
          tenantData?.defaultCurrency || "PLN"
        ) +
        " - " +
        formatCurrency(priceForService[1], tenantData?.defaultCurrency || "PLN")
      );
    }
  }, [priceForService, selectedServiceId, tenantData?.defaultCurrency, t]);

  const executeOnClick = () => {
    setIsExpanded(!isExpanded);
  };

  if (calendarOnly) {
    return (
      <Box
        p={15}
        boxShadow={"0 4px 24px 0 rgba(34,41,47,.1)"}
        m={0}
        border="1px solid #e5e5e5"
      >
        <SwiperComponentUnix
          publicAvailability={availability}
          buttonColor={tenantData?.widget?.buttonColor}
          fontColor={tenantData?.widget?.fontColor}
          selectedLocationId={selectedLocationId}
          minimumHoursBeforeBooking={
            tenantData?.widget?.minimumHoursBeforeBooking
          }
          serviceDuration={null}
          defaultSelectedTimeslot={null}
          maxDays={tenantData?.widget?.maxDays || 14}
          visibleDays={7}
        />
      </Box>
    );
  }
  return (
    <Box
      height={isExpanded ? "initial" : { md: 350, s: "initial" }}
      p={15}
      boxShadow={"0 4px 24px 0 rgba(34,41,47,.1)"}
      m={isSingleUserView ? 0 : 5}
      border="1px solid #e5e5e5"
    >
      <VStack>
        <Grid templateColumns="repeat(5, 2fr)" padding={"5px"}>
          <GridItem
            colSpan={
              isSingleUserView
                ? 5
                : {
                    base: 5,
                    sm: 5,
                    md: 2,
                    lg: window.location.href.includes("osrodek") ? 3 : 3,
                  }
            }
          >
            <Grid
              templateColumns="repeat(5, 1fr)"
              borderRight={
                isSingleUserView
                  ? {}
                  : {
                      base: "1px solid #eef0f5",
                      sm: "none",
                      md: "1px solid #eef0f5",
                      lg: "1px solid #eef0f5",
                    }
              }
              borderBottom={
                isSingleUserView
                  ? "1px solid #eef0f5"
                  : {
                      base: "none",
                      sm: "1px solid #eef0f5",
                      md: "none",
                      lg: "none",
                    }
              }
              paddingRight={
                isSingleUserView
                  ? 0
                  : {
                      base: "5px",
                      sm: "0px",
                      md: "5px",
                      lg: "5px",
                    }
              }
            >
              <GridItem
                colSpan={
                  isSingleUserView
                    ? 2
                    : isCompactView
                      ? 2
                      : {
                          base: 5,
                          sm: 2,
                          md: 5,
                          lg: 5,
                        }
                }
                rowSpan={isCompactView ? 0 : 1}
                minH={"200px"}
                justifySelf="center"
              >
                <Box
                  w={"100%"}
                  maxWidth={"200px"}
                  maxHeight={"200px"}
                  padding={"15px"}
                  overflow={"hidden"}
                  justifyContent={"center"}
                  display="flex"
                >
                  <Image
                    src={user.avatar}
                    alt="User Avatar"
                    borderRadius="lg"
                    objectFit={"cover"}
                    padding={0}
                    width={"100%"}
                    overflow="hidden"
                    border="none"
                  />
                </Box>
              </GridItem>
              <GridItem
                padding={isCompactView ? "15px" : "initial"}
                colSpan={
                  isSingleUserView
                    ? 3
                    : isCompactView
                      ? 3
                      : {
                          base: 5,
                          sm: 3,
                          md: 5,
                          lg: 5,
                        }
                }
                rowSpan={1}
              >
                <Link
                  href={user.link}
                  isExternal
                  color="#1C2F4B"
                  fontSize="18px"
                  fontWeight="600"
                  lineHeight="22px"
                  textAlign="left"
                >
                  {availability.lastName} {availability.firstName}
                </Link>
                <Flex marginTop={"10px"}>
                  <VStack align="start">
                    {userLocationsNames.map((locationName) => (
                      <Box key={locationName} display={"flex"}>
                        <LocationIcon />
                        <Text
                          color="#1C2F4B"
                          fontSize="14px"
                          fontWeight="400"
                          lineHeight={"18px"}
                          margin={"0px 0px 0px 5px !important"}
                        >
                          {locationName}
                        </Text>
                      </Box>
                    ))}
                  </VStack>
                </Flex>
                {!isSingleUserView && (
                  <Text
                    color="#1C2F4B"
                    fontSize="14px"
                    fontWeight="400"
                    margin={"10px 0px 0px 0px !important"}
                  >
                    {priceLabel}
                  </Text>
                )}
                {!!tenantData?.widget?.renderTherapyTypes && (
                  <Text
                    color="#1C2F4B"
                    fontSize="14px"
                    fontWeight="400"
                    margin={"10px 0px 0px 0px !important"}
                  >
                    <Text
                      as="span"
                      color="rgba(28, 47, 75, 0.70)"
                      margin={"0px 0px 0px 0px !important"}
                    >
                      {t(tenantData?.widget?.therapyTypesLabel)}:
                    </Text>{" "}
                    {user.theraphyTypes || "-"}
                  </Text>
                )}
              </GridItem>
              {!tenantData?.widget.hideBio && !isSingleUserView && (
                <GridItem colSpan={5} marginTop={"15px"}>
                  <Box>
                    <StyledShowMoreText
                      lines={3}
                      onClick={executeOnClick}
                      more={t("Show more")}
                      less={t("Show less")}
                      anchorClass="show-more-less-clickable"
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      {parse(user.bio)}
                    </StyledShowMoreText>
                  </Box>
                </GridItem>
              )}
            </Grid>
          </GridItem>
          <GridItem
            colSpan={
              isSingleUserView
                ? 5
                : {
                    base: 5,
                    sm: 5,
                    md: 3,
                    lg: window.location.href.includes("osrodek") ? 3 : 2,
                  }
            }
            paddingLeft={"10px"}
            paddingRight={"10px"}
            paddingTop={"10px"}
          >
            <SwiperComponentUnix
              publicAvailability={availability}
              buttonColor={tenantData?.widget?.buttonColor}
              fontColor={tenantData?.widget?.fontColor}
              selectedLocationId={selectedLocationId}
              minimumHoursBeforeBooking={
                tenantData?.widget?.minimumHoursBeforeBooking
              }
              serviceDuration={null}
              defaultSelectedTimeslot={null}
              maxDays={tenantData?.widget?.maxDays || 14}
              visibleDays={4}
            />
          </GridItem>
        </Grid>
      </VStack>
    </Box>
  );
};

export default UserCard;
