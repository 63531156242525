import { Box } from "@chakra-ui/react";
import { useSwiper } from "swiper/react";

export const ArrowLeftIcon = (): any => {
  const swiper = useSwiper();

  return (
    <Box
      zIndex={2}
      position="absolute"
      top="0"
      left="0"
      onClick={() => swiper.slidePrev()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <g clipPath="url(#clip0_3_1430)">
          <path
            d="M11.25 4.5L6.75 9L11.25 13.5"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_3_1430">
            <rect width="18" height="18" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
