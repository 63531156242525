// SearchContext.tsx
import type { PublicLocation } from "practicare/types/location.model";
import type { PublicAvailability } from "practicare/types/publicAvailability.model";
import type { PublicService } from "practicare/types/service.model";
import type { CustomerProblems, PublicUser } from "practicare/types/user.model";
import React, { createContext, ReactNode, useContext, useState } from "react";
import type { SORT, TIMESLOTS } from "src/config/consts";
import { SORT as SortEnum } from "src/config/consts";
interface Search {
  location: PublicLocation | null;
  problem: CustomerProblems | null;
  user: PublicUser | null;
  service: PublicService | null;
  timeSlot: TIMESLOTS | null;
  selectedTimeSlotId: string | null;
  selectedAvailability: PublicAvailability | null;
  sort: SORT;
}

interface SearchProps {
  search: Search;
  setSearch: React.Dispatch<React.SetStateAction<Search>>;
}

const SearchContext = createContext<SearchProps | undefined>(undefined);

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("UseSearch must be used within a SearchProvider");
  }
  return context;
};

interface SearchProviderProps {
  children: ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [search, setSearch] = useState<Search>({
    location: null,
    problem: null,
    user: null,
    service: null,
    timeSlot: null,
    sort: SortEnum.AVAILABILITY,
    selectedAvailability: null,
    selectedTimeSlotId: null,
  });

  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      {children}
    </SearchContext.Provider>
  );
};
