import { Box, SimpleGrid } from "@chakra-ui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { SORT, TIMESLOTS } from "src/config/consts";
import { useSearch } from "src/context/SearchContext";
import SelectComponent from "../components/SelectComponent";
import { useTenantData } from "../context/TenantDataContext";

export const SelectGrid: React.FC = () => {
  const { tenantData } = useTenantData();
  const { search, setSearch } = useSearch();
  const { t } = useTranslation();

  const availableTimeSlotsOptions = [
    { id: TIMESLOTS.TOMORROW, name: t("Tomorrow") },
    { id: TIMESLOTS.WITHIN_3_DAYS, name: t("Within 3 days") },
    { id: TIMESLOTS.ANYTIME, name: t("Whenever") },
  ];

  const sortOptions = [
    { id: SORT.AVAILABILITY, name: t("Sort by availability"), selected: true },
    { id: SORT.NAME, name: t("Sort by name") },
  ];

  return (
    <Box
      border="1px solid #e5e5e5"
      borderRadius={4}
      padding={"15px"}
      backgroundColor={"white"}
    >
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3 }}
        spacingX={30}
        spacingY={15}
        backgroundColor={"white"}
      >
        <SelectComponent
          placeholder={t("Select service")}
          options={tenantData.selectServices}
          selectedValueId={(search.service as any)?.idSelect}
          onSelectChange={(e) => {
            if (e.includes("-online") && !search.location?.isOnline) {
              setSearch({
                ...search,
                location:
                  tenantData.publicLocations.find((l) => l.isOnline) || null,
                service:
                  (tenantData.selectServices.find(
                    (s) => s.idSelect === e
                  ) as any) || null,
              });
            } else if (e.includes("-offline") && search.location?.isOnline) {
              setSearch({
                ...search,
                location: null,
                service:
                  (tenantData.selectServices.find(
                    (s) => s.idSelect === e
                  ) as any) || null,
              });
            } else {
              setSearch({
                ...search,
                service:
                  (tenantData.selectServices.find(
                    (s) => s.idSelect === e
                  ) as any) || null,
              });
            }
          }}
        />
        <SelectComponent
          placeholder={t("Select location")}
          options={tenantData.publicLocations.map((l) => ({
            id: l.id,
            name:
              l.locationName + (l.isOnline ? "" : " - " + l.locationAddress),
          }))}
          selectedValueId={search.location?.id}
          onSelectChange={(e) =>
            setSearch({
              ...search,
              location:
                tenantData.publicLocations.find((s) => s.id === e) || null,
            })
          }
        />
        <SelectComponent
          placeholder={t("Select availability")}
          options={availableTimeSlotsOptions}
          selectedValueId={search.timeSlot}
          onSelectChange={(e) =>
            setSearch({
              ...search,
              timeSlot: e as any,
            })
          }
        />
        <SelectComponent
          placeholder={t("Select therapist")}
          options={tenantData.publicUsers}
          selectedValueId={search.user?.id}
          onSelectChange={(e) =>
            setSearch({
              ...search,
              user: tenantData.publicUsers.find((s) => s.id === e) || null,
            })
          }
        />
        <SelectComponent
          placeholder={t("Sorting")}
          options={sortOptions}
          selectedValueId={search.sort}
          onSelectChange={(e) =>
            setSearch({
              ...search,
              sort: e as any,
            })
          }
        />
        <SelectComponent
          placeholder={t("Select problem")}
          options={tenantData.customerProblems}
          selectedValueId={search.problem?.id}
          onSelectChange={(e) =>
            setSearch({
              ...search,
              problem:
                tenantData.customerProblems.find((s) => s.id === e) || null,
            })
          }
        />
      </SimpleGrid>
    </Box>
  );
};
