import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { PublicAvailability } from "practicare/types//publicAvailability.model";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearch } from "src/context/SearchContext";
import { useTenantData } from "src/context/TenantDataContext";
import { ArrowLeftIcon } from "src/utils/ArrowLeftIcon";
import { ArrowRightIcon } from "src/utils/ArrowRightIcon";
import styled from "styled-components";
import "swiper/css";
import { Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { getFormat, getLabel } from "../config/utils";

const StyledTextDay = styled(Text)`
  color: #1c2f4b;
  /* font-family: "Red Hat Display"; */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

const StyledHStack = styled(HStack)`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledTextFormattedDate = styled(Text)`
  color: rgba(28, 47, 75, 0.7);
  /* font-family: "Red Hat Display"; */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledHourContainer = styled(Box)`
  text-align: center;
  width: 45px;
`;

const StyledHourPickerAvailable = styled(Text)`
  border-radius: 4px;
  background-color: ${(props: any) =>
    props.selected ? props.buttonColor : "#fafafa"};
  border: 1px solid lightgrey;
  color: ${(props: any) => (props.selected ? props.fontColor : "#212427")};
  /* font-family: "Red Hat Display"; */
  font-size: 12px;
  font-weight: ${(props: any) => (props.selected ? 800 : 600)};
  line-height: normal;
  cursor: pointer;
  margin: 0;
`;

const StyledHourPickerNotAvailable = styled(Text)`
  font-size: 11px;
  text-decoration: line-through;
  border-radius: 4px;
  color: #1c2f4b;
  /* font-family: "Red Hat Display"; */
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background: #f6f7f9;
  margin: 0;
`;

const StyledHourPickerDifferentLocation = styled(Text)`
  font-size: 11px;
  border-radius: 4px;
  color: #1c2f4b;
  /* font-family: "Red Hat Display"; */
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background: #f6f7f9;
  margin: 0;
`;

export const SwiperComponentUnix = ({
  publicAvailability,
  minimumHoursBeforeBooking,
  buttonColor,
  fontColor,
  selectedLocationId,
  serviceDuration,
  defaultSelectedTimeslot,
  maxDays,
  visibleDays,
}: {
  publicAvailability: PublicAvailability;
  minimumHoursBeforeBooking?: number;
  buttonColor?: string;
  fontColor?: string;
  selectedLocationId: string | undefined;
  serviceDuration: number | null;
  defaultSelectedTimeslot: string | null;
  maxDays: number;
  visibleDays: number;
}) => {
  const { search, setSearch } = useSearch();
  const { t } = useTranslation();
  const [swiper, setSwiperInstance] = useState<any | undefined>();
  const { tenantData, widgetLocationId } = useTenantData();
  const selectHourSlot = (hourId: string) => {
    setSearch({
      ...search,
      selectedAvailability: publicAvailability,
      selectedTimeSlotId: hourId,
    });
  };
  // Helper to generate the next 14 days
  const daysArray = useMemo(() => {
    const days = [];
    for (let i = 0; i < maxDays; i++) {
      days.push(moment().add(i, "days").format("YYYY-MM-DD"));
    }
    return days;
  }, [maxDays]); // Empty dependency means it only runs once on component mount

  const initialSlideIndex = useMemo(() => {
    if (!defaultSelectedTimeslot) return 0;
    const timeSlot = publicAvailability.availabilityUnix.find(
      (a) => a.id === defaultSelectedTimeslot
    );
    if (!timeSlot) return 0;
    const selectedDate = moment
      .unix(timeSlot?.unixTimestamp)
      .format("YYYY-MM-DD");

    return (
      (daysArray.findIndex((date) => date === selectedDate) / visibleDays) | 0
    ); // Get the group index by dividing by 4
  }, [defaultSelectedTimeslot, daysArray]);

  const generateTooltip = useCallback(
    (locationIds: string[]) => {
      const locationNames = tenantData.publicLocations
        .filter((l) => locationIds.includes(l.id))
        .map((l) => l.name);
      return t("Available in") + ": " + locationNames.join(", ");
    },
    [tenantData.publicLocations] // Recompute only when `publicLocations` changes
  );

  const generateSingleSlide = useCallback(
    (dateString: string) => {
      const availableSlots = (publicAvailability.availabilityUnix || []).filter(
        ({ unixTimestamp, locationIds }) => {
          const slotDate = moment.unix(unixTimestamp).format("YYYY-MM-DD");
          return (
            slotDate === dateString &&
            (search.location?.id && !search.location.isOnline
              ? locationIds.includes(search.location?.id)
              : true)
          );
        }
      );

      if (availableSlots.length === 0) {
        return (
          <Box alignItems={"center"} marginTop={"5px"} marginBottom={"5px"}>
            <StyledHourContainer>
              <StyledHourPickerNotAvailable>
                {String.fromCharCode(8287).repeat(1)}
              </StyledHourPickerNotAvailable>
            </StyledHourContainer>
          </Box>
        );
      }
      // Your existing logic for rendering slots
      return availableSlots.map(({ unixTimestamp, id, locationIds }, index) => {
        const hour = moment.unix(unixTimestamp).format("HH:mm");
        const isAvailable = !!id;

        const slotDate = new Date(`${dateString}T${hour}`);

        if (!isAvailable) {
          return (
            <Box alignItems={"center"} marginTop={"5px"} marginBottom={"5px"}>
              <StyledHourContainer>
                <StyledHourPickerNotAvailable>
                  {hour}
                </StyledHourPickerNotAvailable>
              </StyledHourContainer>
            </Box>
          );
        }
        const minimumTimeRestriction = new Date(
          new Date().getTime() +
            (minimumHoursBeforeBooking || 24) * 60 * 60 * 1000
        );
        const isWithin12Hours = slotDate >= minimumTimeRestriction;
        let isProperLocation = selectedLocationId
          ? locationIds.includes(selectedLocationId)
          : true;
        if (widgetLocationId) {
          isProperLocation = locationIds.includes(widgetLocationId);
        }
        if (
          serviceDuration &&
          isWithin12Hours &&
          isAvailable &&
          isProperLocation
        ) {
          // Calculate if the entire service duration fits in the available slots
          const durationBetweenTimeslots =
            availableSlots.length > 1
              ? moment
                  .unix(availableSlots[1].unixTimestamp)
                  .diff(moment.unix(availableSlots[0].unixTimestamp), "minutes")
              : 60;

          const durationInMinutes = serviceDuration || 60; // Assuming default is 60 mins if not provided
          const slotsNeeded = Math.ceil(
            durationInMinutes / durationBetweenTimeslots
          ); // Assuming 30-minute slots, adjust if needed
          let hasConsecutiveSlots = true;
          // Check if there are enough consecutive available slots
          for (let i = 0; i < slotsNeeded; i++) {
            const nextSlot = availableSlots[index + i];
            if (
              !nextSlot ||
              !nextSlot.id ||
              (selectedLocationId
                ? !nextSlot.locationIds.includes(selectedLocationId)
                : false)
            ) {
              hasConsecutiveSlots = false;
              break;
            }
          }
          // If no consecutive slots are found, mark the slot as unavailable
          if (!hasConsecutiveSlots) {
            return (
              <Box alignItems={"center"} marginTop={"5px"} marginBottom={"5px"}>
                <Tooltip label={t("Unavailable")}>
                  <StyledHourContainer>
                    <StyledHourPickerDifferentLocation>
                      {hour}
                    </StyledHourPickerDifferentLocation>
                  </StyledHourContainer>
                </Tooltip>
              </Box>
            );
          }
        }

        return (
          <Box
            alignItems={"center"}
            marginTop={"5px"}
            marginBottom={"5px"}
            height={"15px"}
          >
            <StyledHourContainer>
              {isWithin12Hours && isAvailable ? (
                isProperLocation ? (
                  <StyledHourPickerAvailable
                    buttonColor={buttonColor}
                    fontColor={fontColor}
                    selected={id === defaultSelectedTimeslot}
                    onClick={() => selectHourSlot(id as string)}
                  >
                    {hour}
                  </StyledHourPickerAvailable>
                ) : (
                  <Tooltip label={generateTooltip(locationIds)}>
                    <StyledHourPickerDifferentLocation
                      buttonColor={buttonColor}
                      fontColor={fontColor}
                    >
                      {hour}
                    </StyledHourPickerDifferentLocation>
                  </Tooltip>
                )
              ) : (
                <StyledHourPickerNotAvailable>
                  {hour}
                </StyledHourPickerNotAvailable>
              )}
            </StyledHourContainer>
          </Box>
        );
      });
    },
    [
      publicAvailability,
      search.location?.id,
      minimumHoursBeforeBooking,
      selectedLocationId,
      serviceDuration,
      defaultSelectedTimeslot,
    ]
  );

  const navigateToNextAvailableSlotSlide = (
    nextDate: string, // The next available date in string format
    daysInGroupsOfFour: string[][] // An array of arrays of date strings
  ): void => {
    const nextAvailableMoment = moment(nextDate, "YYYY-MM-DD");

    const slideIndex = daysInGroupsOfFour.findIndex((daysGroup) =>
      daysGroup.some((dateString) =>
        moment(dateString, "YYYY-MM-DD").isSame(nextAvailableMoment, "day")
      )
    );

    if (slideIndex !== -1 && swiper) {
      swiper.slideTo(slideIndex);
    }
  };

  const getNextAvailableSlotAfterDate = useCallback(
    (lastDate: Date) => {
      for (const availability of publicAvailability.availabilityUnix || []) {
        const slotDate = moment.unix(availability.unixTimestamp);
        if (
          availability.id !== false &&
          availability.booked !== true &&
          slotDate >= moment(lastDate) &&
          (selectedLocationId
            ? availability.locationIds.includes(selectedLocationId || "")
            : true)
        ) {
          return slotDate.format("YYYY-MM-DD HH:mm");
        }
      }
      return null; // No available slot
    },
    [publicAvailability, selectedLocationId]
  );

  const generateSlides = useMemo(() => {
    const daysInGroupsOfFour: string[][] = [];

    for (let i = 0; i < daysArray.length; i += visibleDays) {
      daysInGroupsOfFour.push(daysArray.slice(i, i + visibleDays));
    }

    const slides = daysInGroupsOfFour.map((daysGroup, groupIndex) => {
      const isLastSlide = groupIndex === daysInGroupsOfFour.length - 1;

      const groupHasSlots = daysGroup.some((dateString) => {
        const availableSlots = (
          publicAvailability.availabilityUnix || []
        ).filter(
          ({ unixTimestamp, id }) =>
            moment.unix(unixTimestamp).format("YYYY-MM-DD") === dateString &&
            moment
              .unix(unixTimestamp)
              .isAfter(
                moment().add(minimumHoursBeforeBooking || 24, "hours")
              ) &&
            id
        );
        return availableSlots.length > 0;
      });

      const lastDate = moment(daysGroup[daysGroup.length - 1]).toDate();

      const nextAvailable = getNextAvailableSlotAfterDate(lastDate);
      const randomNumber = Math.floor(Math.random() * 100000);
      return (
        <SwiperSlide key={groupIndex}>
          <Grid
            w="100%"
            position="fixed"
            templateColumns={
              groupIndex !== daysInGroupsOfFour.length - 1
                ? `repeat(${visibleDays}, 1fr)`
                : `repeat(${
                    maxDays % visibleDays === 0
                      ? visibleDays
                      : maxDays % visibleDays
                  }, 1fr)`
            }
            gap={0}
            padding={"0px 7px 0px 7px"}
          >
            {daysGroup.map((dateString) => (
              <GridItem key={dateString}>
                <VStack
                  justify="center"
                  w="100%"
                  textAlign={"center"}
                  alignItems={"center"}
                  marginBottom={"15px"}
                  backgroundColor="white"
                  borderBottom="1px solid #EEEFF2"
                >
                  <Box key={dateString} whiteSpace="nowrap">
                    <StyledTextDay>
                      {t(getLabel(new Date(dateString)))}
                    </StyledTextDay>
                    <StyledTextFormattedDate>
                      {getFormat(new Date(dateString))}
                    </StyledTextFormattedDate>
                  </Box>
                </VStack>
              </GridItem>
            ))}
          </Grid>

          <StyledHStack
            w="100%"
            className={`id-${publicAvailability.userId}-${groupIndex}-${randomNumber}`} // Ensure valid CSS class
            height={200}
            overflowX={"scroll"}
            scrollbar={0}
            marginTop={"50px"}
            marginBottom={"15px"}
            alignItems={"inherit"}
            padding={"0px 7px 0px 7px"}
            gap={0}
          >
            {daysGroup.map((dateString) => (
              <Box key={dateString} w="100%">
                <VStack w="100%" gap={0}>
                  {generateSingleSlide(dateString)}
                </VStack>
              </Box>
            ))}
          </StyledHStack>
          {/* <ScrollIndicator
            buttonColor={buttonColor || ""}
            color={fontColor || ""}
            bottomMargin={!groupHasSlots ? "70px" : "0px"}
            parentSelector={`.id-${publicAvailability.userId}-${groupIndex}-${randomNumber}`} // Match with the class
          /> */}
          {!groupHasSlots && (
            <Box
              width="90%"
              margin="auto"
              marginBottom="10px"
              alignContent={"center"}
              zIndex={10}
              backgroundColor="rgba(255, 255, 255, 0.9)"
              padding="10px"
              display="flex"
              justifyContent="center"
              alignItems={"center"}
              borderRadius={nextAvailable && !isLastSlide ? "" : "8px"}
              boxShadow={
                nextAvailable && !isLastSlide
                  ? ""
                  : "0 2px 6px rgba(0, 0, 0, 0.1)"
              }
            >
              {nextAvailable && !isLastSlide ? (
                <Button
                  backgroundColor={buttonColor}
                  color={fontColor}
                  maxWidth="80%"
                  cursor={"pointer"}
                  padding={"10px"}
                  fontSize="10px"
                  borderRadius={"8px"}
                  boxShadow={"0 2px 6px rgba(0, 0, 0, 0.1)"}
                  onClick={() => {
                    navigateToNextAvailableSlotSlide(
                      nextAvailable,
                      daysInGroupsOfFour
                    );
                  }}
                >
                  {t("Next available slot")}
                  <br />
                  {nextAvailable}
                </Button>
              ) : (
                <Text fontSize="10px" maxWidth="90%">
                  {t(
                    "No available slots past this date- contact us to book a slot in the future"
                  )}
                </Text>
              )}
            </Box>
          )}
        </SwiperSlide>
      );
    });

    return slides;
  }, [daysArray, generateSingleSlide, t, visibleDays]);

  return (
    <Swiper
      onSwiper={(swiper: any) => setSwiperInstance(swiper)}
      slidesPerView={1}
      modules={[Virtual]}
      virtual
      initialSlide={initialSlideIndex}
    >
      <ArrowLeftIcon />
      <ArrowRightIcon />
      {generateSlides}
    </Swiper>
  );
};
