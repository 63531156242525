import { Box } from "@chakra-ui/react";
import { useSwiper } from "swiper/react";

export const ArrowRightIcon = (): any => {
  const swiper = useSwiper();
  return (
    <Box
      zIndex={2}
      position="absolute"
      top="0"
      right="0"
      onClick={() => swiper.slideNext()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <g clipPath="url(#clip0_3_1456)">
          <path
            d="M6.75 4.5L11.25 9L6.75 13.5"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_3_1456">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="matrix(-1 0 0 1 18 0)"
            ></rect>
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
