export const LocationIcon = (): any => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_1387)">
      <path
        d="M5.25 6.91667C5.25 7.3808 5.43437 7.82592 5.76256 8.15411C6.09075 8.4823 6.53587 8.66667 7 8.66667C7.46413 8.66667 7.90925 8.4823 8.23744 8.15411C8.56563 7.82592 8.75 7.3808 8.75 6.91667C8.75 6.45254 8.56563 6.00742 8.23744 5.67923C7.90925 5.35105 7.46413 5.16667 7 5.16667C6.53587 5.16667 6.09075 5.35105 5.76256 5.67923C5.43437 6.00742 5.25 6.45254 5.25 6.91667Z"
        stroke="#BCBCBC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M10.2999 10.2166L7.82485 12.6917C7.60609 12.9102 7.30952 13.033 7.0003 13.033C6.69109 13.033 6.39452 12.9102 6.17576 12.6917L3.7001 10.2166C3.04747 9.56393 2.60304 8.73241 2.423 7.82717C2.24295 6.92193 2.33538 5.98363 2.6886 5.13092C3.04181 4.27822 3.63995 3.5494 4.40738 3.03663C5.1748 2.52386 6.07704 2.25017 7.00001 2.25017C7.92298 2.25017 8.82522 2.52386 9.59265 3.03663C10.3601 3.5494 10.9582 4.27822 11.3114 5.13092C11.6646 5.98363 11.7571 6.92193 11.577 7.82717C11.397 8.73241 10.9525 9.56393 10.2999 10.2166Z"
        stroke="#BCBCBC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_3_1387">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        ></rect>
      </clipPath>
    </defs>
  </svg>
);
