// TenantDataContext.tsx
import { query, where } from "firebase/firestore";
import { PublicLocation } from "practicare/types/location.model";
import { PublicAvailability } from "practicare/types/publicAvailability.model";
import { PublicService } from "practicare/types/service.model";
import { PublicTenantConfig } from "practicare/types/tenant.model";
import { PublicUser, type CustomerProblems } from "practicare/types/user.model";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { findFirstAvailableSlot } from "src/config/utils";
import { collection, db, doc, onSnapshot } from "../config/firebaseConfig";

interface TenantData {
  selectServices: {
    id: string;
    idSelect: string;
    name: string;
    isDisabled: boolean;
    isOnline: boolean;
  }[];
  publicAvailability: PublicAvailability[];
  publicLocations: PublicLocation[];
  publicServices: PublicService[];
  publicUsers: PublicUser[];
  customerProblems: CustomerProblems[];
  publicTenantConfig?: PublicTenantConfig;
  loading: boolean;
}

interface TenantDataContextProps {
  tenantData: TenantData;
  setTenantData: React.Dispatch<React.SetStateAction<TenantData>>;
  isSmall: boolean;
  widgetUserId: string | null;
  widgetLocationId: string | null;
  widgetServiceId: string | null;
  widgetUserVariantId: string | null;
  calendarOnly: boolean;
}

const TenantDataContext = createContext<TenantDataContextProps | undefined>(
  undefined
);

export const useTenantData = () => {
  const context = useContext(TenantDataContext);
  if (context === undefined) {
    throw new Error("useTenantData must be used within a TenantDataProvider");
  }
  return context;
};

interface TenantDataProviderProps {
  children: ReactNode;
  widgetUserId: string | null;
  widgetLocationId: string | null;
  widgetServiceId: string | null;
  widgetUserVariantId: string | null;
  isSmall: boolean;
  calendarOnly: boolean;
}

export const TenantDataProvider: React.FC<TenantDataProviderProps> = ({
  widgetUserId,
  widgetLocationId,
  widgetServiceId,
  widgetUserVariantId,
  isSmall,
  calendarOnly,
  children,
}) => {
  const [tenantData, setTenantData] = useState<TenantData>({
    publicAvailability: [],
    publicLocations: [],
    publicServices: [],
    publicUsers: [],
    customerProblems: [],
    selectServices: [],
    publicTenantConfig: undefined,
    loading: true,
  });
  const [isSpecificUser, setIsSpecificUser] = useState<PublicUser | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribeConfig = onSnapshot(
      doc(db, "config", "publicTenantSettings"),
      (snapshot: any) => {
        const publicTenantConfig: PublicTenantConfig = snapshot.data();
        setTenantData((prevData) => ({
          ...prevData,
          publicTenantConfig: publicTenantConfig,
        }));
      }
    );
    return () => {
      unsubscribeConfig();
    };
  }, []);

  useEffect(() => {
    const unsubscribeAvailability = onSnapshot(
      !widgetUserId
        ? collection(db, "publicAvailability")
        : query(
            collection(db, "publicAvailability"),
            where("userId", "==", widgetUserId)
          ),
      (snapshot: any) => {
        const availabilityData: PublicAvailability[] = snapshot.docs
          .map((doc: any) => doc.data() as PublicAvailability)
          .filter((a: PublicAvailability) => {
            return widgetUserId
              ? true
              : a.userId !== "xvdh31BcIMdO9IcNxtmau46NC4p2";
          });
        let dataWithFirstAvailable = availabilityData.map((a) => ({
          ...a,
          firstAvailableDate: findFirstAvailableSlot(
            a,
            tenantData.publicTenantConfig?.widget.minimumHoursBeforeBooking
          ),
        }));

        if (isSpecificUser) {
          dataWithFirstAvailable = dataWithFirstAvailable.filter(
            (a) => a.userId === isSpecificUser.id
          );
        }

        setTenantData((prevData) => ({
          ...prevData,
          publicAvailability: dataWithFirstAvailable,
          loading: false,
        }));
      }
    );

    const unsubscribeLocations = onSnapshot(
      collection(db, "publicLocations"),
      (snapshot: any) => {
        const locationsData: PublicLocation[] = snapshot.docs
          .map((doc: any) => ({
            ...(doc.data() as PublicLocation),
            id: doc.id,
          }))
          .sort((a: PublicLocation, b: PublicLocation) => {
            return a.locationName.localeCompare(b.locationName);
          });
        setTenantData((prevData) => ({
          ...prevData,
          publicLocations: locationsData,
        }));
      }
    );

    const unsubscribeServices = onSnapshot(
      collection(db, "publicServices"),
      (snapshot: any) => {
        const servicesData: PublicService[] = snapshot.docs.map((doc: any) => ({
          ...(doc.data() as PublicService),
          id: doc.id,
        }));
        setTenantData((prevData) => ({
          ...prevData,
          publicServices: servicesData,
        }));
      }
    );

    const unsubscribeUsers = onSnapshot(
      collection(db, "publicUsers"),
      (snapshot: any) => {
        const usersData: PublicUser[] = snapshot.docs
          .map((doc: any) => ({
            ...(doc.data() as PublicUser),
            id: doc.id,
          }))
          .sort((a: PublicUser, b: PublicUser) => {
            return a.name.localeCompare(b.name);
          });
        const isOnSpecificUserLink =
          usersData.find((u) => u.link === window.location.href) || null;
        setIsSpecificUser(isOnSpecificUserLink);
        setTenantData((prevData) => ({ ...prevData, publicUsers: usersData }));
      }
    );

    const unsubscribeCustomerProblems = onSnapshot(
      collection(db, "customerProblems"),
      (snapshot: any) => {
        const problemsData: CustomerProblems[] = snapshot.docs
          .map((doc: any) => ({
            ...(doc.data() as CustomerProblems),
            id: doc.id,
          }))
          .sort((a: CustomerProblems, b: CustomerProblems) => {
            return a.name.localeCompare(b.name);
          });
        setTenantData((prevData) => ({
          ...prevData,
          customerProblems: problemsData,
        }));
      }
    );

    return () => {
      unsubscribeAvailability();
      unsubscribeLocations();
      unsubscribeServices();
      unsubscribeUsers();
      unsubscribeCustomerProblems();
    };
  }, [tenantData.publicTenantConfig]);

  React.useEffect(() => {
    const sortServices = (
      property: "widgetOfflineDisplay" | "widgetOnlineDisplay",
      services: PublicService[]
    ) => {
      return services.sort((a, b) => {
        if (
          a[property].displayIndex !== undefined &&
          b[property].displayIndex !== undefined
        ) {
          return a[property].displayIndex - b[property].displayIndex; // Sort by displayIndex if both have it
        }
        if (a[property].displayIndex !== undefined) {
          return -1; // a has displayIndex, b does not, so a comes first
        }
        if (b[property].displayIndex !== undefined) {
          return 1; // b has displayIndex, a does not, so b comes first
        }
        // If both don't have displayIndex, sort by name alphabetically
        return a[property].serviceDisplayName.localeCompare(
          b[property].serviceDisplayName
        );
      });
    };

    const offlineServices = sortServices(
      "widgetOfflineDisplay",
      tenantData.publicServices
    )
      .filter((s) => !s.widgetOfflineDisplay.hidden)
      .map((s) => ({
        id: s.id,
        idSelect: s.id + "-offline",
        name: s.widgetOfflineDisplay.serviceDisplayName,
        isDisabled: s.widgetOfflineDisplay.locked,
        isOnline: false,
      }));

    const onlineServices = sortServices(
      "widgetOnlineDisplay",
      tenantData.publicServices
    )
      .filter((s) => !s.widgetOnlineDisplay.hidden)
      .map((s) => ({
        id: s.id,
        idSelect: s.id + "-online",
        name: s.widgetOnlineDisplay.serviceDisplayName,
        isDisabled: s.widgetOnlineDisplay.locked,
        isOnline: true,
      }));

    // Ensure availableServices is reset and only holds current services
    const services = tenantData.publicTenantConfig?.widget
      .splitOnlineAndOfflineServices
      ? [
          ...(offlineServices.length
            ? [
                {
                  name: "---" + t("Offline services") + "---",
                  isDisabled: true,
                },
                ...offlineServices,
              ]
            : []),
          ...(onlineServices.length
            ? [
                {
                  name: "---" + t("Online services") + "---",
                  isDisabled: true,
                } as any,
                ...onlineServices,
              ]
            : []),
        ]
      : [...offlineServices];

    // Reset availableServices to prevent duplicating options
    setTenantData((prevData) => ({
      ...prevData,
      selectServices: services,
    }));
  }, [tenantData.publicServices, tenantData.publicTenantConfig?.widget]);

  useEffect(() => {
    const newPublicAvailability = tenantData.publicAvailability.filter((p) =>
      isSpecificUser ? p.userId === isSpecificUser.id : true
    );
    setTenantData((prevData) => ({
      ...prevData,
      publicAvailability: newPublicAvailability,
    }));
  }, [isSpecificUser]);

  return (
    <TenantDataContext.Provider
      value={{
        tenantData,
        setTenantData,
        isSmall,
        widgetUserId,
        widgetLocationId,
        widgetServiceId,
        widgetUserVariantId,
        calendarOnly,
      }}
    >
      {children}
    </TenantDataContext.Provider>
  );
};
