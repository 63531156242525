import { Box, Spinner, Text } from "@chakra-ui/react";
import moment from "moment";
import type { PublicAvailability } from "practicare/types/publicAvailability.model";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { SORT, TIMESLOTS } from "src/config/consts";
import { useSearch } from "src/context/SearchContext";
import UserCard from "../components/UserCard";
import { useTenantData } from "../context/TenantDataContext";

interface UserGridState {
  filteredPublicAvailability: PublicAvailability[];
}
export const UserGrid: React.FC = () => {
  const { tenantData, isSmall, widgetLocationId, calendarOnly } =
    useTenantData();
  const { t } = useTranslation();
  const { search } = useSearch();

  const filteredPublicAvailability = React.useMemo(() => {
    const filtered: PublicAvailability[] = [];
    const nonMatchingLocationEntries: PublicAvailability[] = [];

    const serviceId = search.service
      ? (search.service as any).idSelect.split("-")[0]
      : false;
    const isServiceOnline = serviceId
      ? (search.service as any).idSelect.includes("-online")
      : false;

    tenantData?.publicAvailability.forEach((availability) => {
      let isFilterOkay = true;
      let addedToNonMatchingLocation = false; // Flag to track if the entry is added to non-matching list

      if (search.user) {
        if (availability.userId !== search.user.id) {
          return;
        }
      }

      if (isFilterOkay && search.problem) {
        isFilterOkay = availability.customerProblemsIds.includes(
          search.problem.id
        );
        if (!isFilterOkay) {
          return;
        }
      }

      if (isFilterOkay && search.service && serviceId) {
        const selectedService = availability.userAvailableServices.filter(
          (uas) =>
            uas.serviceId === serviceId &&
            (isServiceOnline ? uas.isOnline : !uas.isOnline)
        );

        isFilterOkay = Boolean(selectedService.length);
        if (!isFilterOkay) {
          return;
        }
      }

      if (isFilterOkay && search.location) {
        const isOnlineLocation = search.location.isOnline;
        const locationMatches =
          isOnlineLocation &&
          tenantData.publicTenantConfig?.widget.addOnlineToLocations
            ? true
            : availability.locationIds.includes(search.location.id);

        if (!locationMatches) {
          // Push to non-matching location entries instead of filtering out
          nonMatchingLocationEntries.push(availability);
          addedToNonMatchingLocation = true; // Set flag indicating it's already added to non-matching
          isFilterOkay = false;
        }
      }

      if (isFilterOkay && search.timeSlot) {
        const firstAvailableDate = moment(availability.firstAvailableDate);
        const inThreeDays = moment().add(3, "days").format("YYYY-MM-DD");

        switch (search.timeSlot) {
          case TIMESLOTS.TOMORROW:
            if (
              !firstAvailableDate.isBefore(
                moment().add(1, "days").format("YYYY-MM-DD")
              )
            ) {
              return;
            }
            break;
          case TIMESLOTS.WITHIN_3_DAYS:
            if (
              !firstAvailableDate.isBetween(
                moment().format("YYYY-MM-DD"),
                inThreeDays,
                null,
                "[]"
              )
            ) {
              return;
            }
            break;
          case TIMESLOTS.ANYTIME:
            break;
          default:
            throw new Error("Invalid TIMESLOTS value");
        }
      }

      // Only add to the filtered list if it's not already in non-matching
      if (!addedToNonMatchingLocation) {
        filtered.push(availability);
      }
    });

    // Sort the filtered and non-matching location entries
    filtered.sort((a, b) => {
      if (search.sort === SORT.NAME) {
        return a.lastName.localeCompare(b.lastName);
      } else if (search.sort === SORT.AVAILABILITY) {
        return moment(a.firstAvailableDate).diff(moment(b.firstAvailableDate));
      }
      return 0;
    });

    nonMatchingLocationEntries.sort((a, b) => {
      if (search.sort === SORT.NAME) {
        return a.lastName.localeCompare(b.lastName);
      } else if (search.sort === SORT.AVAILABILITY) {
        return moment(a.firstAvailableDate).diff(moment(b.firstAvailableDate));
      }
      return 0;
    });

    return [...filtered, ...nonMatchingLocationEntries];
  }, [search, tenantData]);

  const sortedPublicAvailability = React.useMemo(() => {
    const result = [...filteredPublicAvailability];

    result.sort((a, b) => {
      if (search.sort === SORT.NAME) {
        return a.lastName.localeCompare(b.lastName);
      } else if (search.sort === SORT.AVAILABILITY) {
        return moment(a.firstAvailableDate).diff(moment(b.firstAvailableDate));
      }
      return 0;
    });

    return result;
  }, [filteredPublicAvailability, search.sort]);

  if (tenantData.loading) {
    return (
      <React.Fragment>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      </React.Fragment>
    );
  }
  if (sortedPublicAvailability.length > 0) {
    return (
      <React.Fragment>
        {sortedPublicAvailability.map((availability) => {
          const user = tenantData.publicUsers.find(
            (u) => u.id === availability.userId
          );
          return user ? (
            <Box key={availability.userId}>
              <UserCard
                tenantData={tenantData?.publicTenantConfig}
                locations={tenantData.publicLocations}
                availability={availability}
                selectedLocationId={search.location?.id || ""}
                selectedServiceId={(search.service as any)?.idSelect || ""}
                user={user}
                widgetLocationId={widgetLocationId}
                isSingleUserView={isSmall}
                calendarOnly={calendarOnly}
                isCompactView={
                  !!tenantData?.publicTenantConfig?.widget?.isCompactView
                }
              ></UserCard>
            </Box>
          ) : null;
        })}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Text
            color="#1C2F4B"
            fontSize="14px"
            fontWeight="400"
            margin={"0px !important"}
          >
            {t("No results")}
          </Text>
        </Box>
      </React.Fragment>
    );
  }
};
