import { ColorModeScript } from "@chakra-ui/react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import { App } from "./App";
import de from "./config/locales/de.json";
import en from "./config/locales/en.json";
import es from "./config/locales/es.json";
import fr from "./config/locales/fr.json";
import pl from "./config/locales/pl.json";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "pl",
    fallbackLng: "pl",
    debug: false,
    keySeparator: false,
    resources: {
      pl: {
        translation: pl,
      },
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    load: "all",
  });

const container = document.getElementById("practicare-calendar");
const containerSmall = document.getElementById("practicare-calendar-small");
const containerCalendarOnly = document.getElementById(
  "practicare-calendar-only"
);
if (containerSmall) {
  const widgetUserId = containerSmall.getAttribute("data-id"); // Get the data-id
  const widgetLocationId = containerSmall.getAttribute("data-location-id"); // Get the data-id
  const widgetServiceId = containerSmall.getAttribute("data-service-id"); // Get the data-id
  const widgetUserVariantId = containerSmall.getAttribute(
    "data-user-service-id"
  ); // Get the data-variant
  const root2 = ReactDOM.createRoot(containerSmall);
  root2.render(
    <React.StrictMode>
      <ColorModeScript />
      <App
        widgetUserId={widgetUserId}
        widgetLocationId={widgetLocationId}
        widgetServiceId={widgetServiceId}
        widgetUserVariantId={widgetUserVariantId}
        isSmall={true}
        calendarOnly={false}
      />
    </React.StrictMode>
  );
}

if (containerCalendarOnly) {
  const widgetUserId = containerCalendarOnly.getAttribute("data-id"); // Get the data-id
  const widgetLocationId =
    containerCalendarOnly.getAttribute("data-location-id"); // Get the data-id
  const widgetServiceId = containerCalendarOnly.getAttribute("data-service-id"); // Get the data-id
  const widgetUserVariantId = containerCalendarOnly.getAttribute(
    "data-user-service-id"
  ); // Get the data-variant
  const root2 = ReactDOM.createRoot(containerCalendarOnly);
  root2.render(
    <React.StrictMode>
      <ColorModeScript />
      <App
        widgetUserId={widgetUserId}
        widgetLocationId={widgetLocationId}
        widgetServiceId={widgetServiceId}
        widgetUserVariantId={widgetUserVariantId}
        isSmall={false}
        calendarOnly={true}
      />
    </React.StrictMode>
  );
}

if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <ColorModeScript />
      <App
        isSmall={false}
        widgetUserId={null}
        widgetLocationId={null}
        widgetServiceId={null}
        widgetUserVariantId={null}
        calendarOnly={false}
      />
    </React.StrictMode>
  );
}
if (container || containerSmall || containerCalendarOnly) {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorker.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
