import { Select } from "@chakra-ui/react";
import * as React from "react";
interface SelectComponentProps {
  placeholder: string;
  options: any[];
  selectedValueId?: any;
  onSelectChange: (value: string) => void;
  disabled?: boolean;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  placeholder,
  options,
  selectedValueId,
  onSelectChange,
  disabled,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValueId = event.target.value;
    onSelectChange(selectedValueId);
  };
  return (
    <Select
      color={"#555"}
      fontWeight={400}
      fontSize={14}
      placeholder={placeholder}
      value={selectedValueId || ""}
      onChange={handleChange}
      disabled={disabled}
      backgroundColor={"white"}
    >
      {options.map((option) => (
        <option
          key={option.name + option.id + option.isOnline}
          value={option.idSelect ? option.idSelect : option.id}
          disabled={option.isDisabled}
        >
          {option.name}
        </option>
      ))}
    </Select>
  );
};

export default SelectComponent;
