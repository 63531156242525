export enum TIMESLOTS {
  WITHIN_3_DAYS = "WITHIN_3_DAYS",
  ANYTIME = "ANYTIME",
  TOMORROW = "TOMORROW",
}

export enum SORT {
  NAME = "NAME",
  AVAILABILITY = "AVAILABILITY",
}
