import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import {
  UserService,
  type UserAvailableTimeSlots,
} from "practicare/types/publicAvailability.model";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "src/config/utils";
import { useSearch } from "src/context/SearchContext";
import { useTenantData } from "src/context/TenantDataContext";
import styled from "styled-components";
import SelectComponent from "./SelectComponent";
import { SwiperComponentUnix } from "./SwiperComponentUnix";

const StyledModalHeader = styled(ModalHeader)`
  /* font-family: "Red Hat Display" !important; */
  color: rgb(94, 88, 115);
  text-align: center;
`;

const StyledText = styled(Text)`
  color: rgb(94, 88, 115);
  /* font-family: "Red Hat Display" !important; */
`;

const StyledTextError = styled.div`
  color: #ea5455;
  font-size: 10px;
  /* font-family: "Red Hat Display" !important; */
`;

const StyledButton = styled(Button)`
  background-color: ${(props: any) => props.buttonColor};
  color: ${(props: any) => props.fontColor};
  /* font-family: "Red Hat Display" !important; */
`;

const StyledBox = styled(Box)`
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  margin: 10px;
  padding: 15px;
`;
interface ModalState {
  availableLocations: any[];
  availableTimeSlots: UserAvailableTimeSlots;
}

interface SelectedFields {
  selectedUserServiceVariantId: string;
  selectedLocationId: string;
  selectedDuration: number | null;
}
export const ModalComponent: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { search, setSearch } = useSearch();
  const { tenantData, widgetLocationId, widgetServiceId, widgetUserVariantId } =
    useTenantData();
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<ModalState>({
    availableLocations: [],
    availableTimeSlots: {},
  });
  const [availableServices, setAvailableServices] = useState<UserService[]>([]);
  const [selectedFields, setSelectedFields] = useState<SelectedFields>({
    selectedUserServiceVariantId: widgetUserVariantId || "",
    selectedDuration: null,
    selectedLocationId: widgetLocationId || "",
  });

  const [errors, setErrors] = useState({
    selectedUserServiceVariantId: false,
    selectedLocationId: false,
  });

  const prepareData = () => {
    const { selectedAvailability, location } = search;
    if (!selectedAvailability) {
      return;
    }
    let availableTimeSlots = selectedAvailability.availability;

    const userServices = selectedAvailability.userAvailableServices
      .map((s) => {
        const propertyType = s.isOnline
          ? "widgetOnlineDisplay"
          : "widgetOfflineDisplay";
        const serviceSettings = tenantData.publicServices.find(
          (service) =>
            service.id === s.serviceId && !service[propertyType].hidden
        );
        if (serviceSettings) {
          return {
            ...s,
            displayIndex: serviceSettings[propertyType].displayIndex,
          };
        } else {
          return { hide: true };
        }
      })
      .filter((s) => !s.hide);

    const sortServices = (services: any[]) => {
      return services
        .sort((a, b) => a.duration - b.duration)
        .sort((a, b) => {
          if (a.displayIndex !== undefined && b.displayIndex !== undefined) {
            return a.displayIndex - b.displayIndex; // Sort by displayIndex if both have it
          }
          if (a.displayIndex !== undefined) {
            return -1; // a has displayIndex, b does not, so a comes first
          }
          if (b.displayIndex !== undefined) {
            return 1; // b has displayIndex, a does not, so b comes first
          }
          // If both don't have displayIndex, sort by name alphabetically
          return a.variantName.localeCompare(b.variantName);
        });
    };
    const offlineServices = sortServices(
      userServices
        .filter((us: any) =>
          widgetServiceId ? us.serviceId === widgetServiceId : true
        )
        .map((s: any) => ({
          ...s,
          name: `${s.serviceName} ${formatCurrency(s.price, tenantData.publicTenantConfig?.defaultCurrency || "PLN")} (${s.duration} min)`,
        }))
        .filter((service: any) => {
          if (location) {
            if (location.onlyOnline) {
              return !service.isOnline; // Exclude online if location.onlyOnline is true
            }
            if (location.allowOnline) {
              return !service.isOnline; // Filter out online services if only offline allowed
            } else {
              return !service.isOnline; // Keep offline services only
            }
          }
          return !service.isOnline;
        })
    );
    const onlineServices = sortServices(
      userServices
        .filter((us: any) =>
          widgetServiceId ? us.serviceId === widgetServiceId : true
        )
        .map((s: any) => ({
          ...s,
          name: `${s.serviceName} ${formatCurrency(s.price, tenantData.publicTenantConfig?.defaultCurrency || "PLN")} (${s.duration} min)`,
        }))
        .filter((service: any) => {
          if (location) {
            if (location.allowOnline) {
              return service.isOnline; // Include online if allowed
            }
          }
          return service.isOnline;
        })
    );
    // Conditionally add ---Offline--- and ---Online--- headers if services exist
    const newAvailableServices = [
      ...(offlineServices.length
        ? [
            { name: "---" + t("Offline services") + "---", isDisabled: true },
            ...offlineServices,
          ]
        : []),
      ...(onlineServices.length
        ? [
            { name: "---" + t("Online services") + "---", isDisabled: true },
            ...onlineServices,
          ]
        : []),
    ];
    availableTimeSlots = Object.keys(selectedAvailability.availability).reduce(
      (avail, date) => {
        avail[date] = {};
        Object.keys(selectedAvailability.availability[date]).forEach((hour) => {
          if (
            location &&
            selectedAvailability.availability[date][hour].locationId ===
              location.id
          ) {
            avail[date][hour] = selectedAvailability.availability[date][hour];
          }
        });
        return avail;
      },
      {} as UserAvailableTimeSlots
    );
    setAvailableServices(newAvailableServices);
    setModalState({
      ...modalState,
      availableTimeSlots,
    });
  };

  const closeModal = () => {
    setModalState({
      availableLocations: [],
      availableTimeSlots: {},
    });
    setAvailableServices([]);
    setSearch({
      ...search,
      selectedAvailability: null,
      selectedTimeSlotId: null,
    });
    setSelectedFields({
      selectedUserServiceVariantId: "",
      selectedLocationId: widgetLocationId || "",
      selectedDuration: null,
    });
    onClose();
  };

  const onNext = () => {
    let isValid = true;
    if (selectedFields.selectedUserServiceVariantId === "") {
      isValid = false;
    }
    if (selectedFields.selectedLocationId === "") {
      isValid = false;
    }

    if (!isValid) {
      setErrors({
        selectedUserServiceVariantId:
          selectedFields.selectedUserServiceVariantId === "",
        selectedLocationId: selectedFields.selectedLocationId === "",
      });
      return;
    }

    const timeslot = search.selectedAvailability?.availabilityUnix.find(
      (u) => u.id === search.selectedTimeSlotId
    );
    const momentTimeslot = moment.unix(timeslot?.unixTimestamp as number);
    const date = momentTimeslot.format("YYYY-MM-DD");
    const time = momentTimeslot.format("HH:mm");
    const selectedUserServiceVariant =
      search.selectedAvailability?.userAvailableServices.find(
        (u) => u.id === selectedFields.selectedUserServiceVariantId
      );
    window.location.href = `${tenantData.publicTenantConfig?.companyWebsiteUrl}/register?day=${date}&hour=${time}&availabilityId=${search.selectedAvailability?.userId}&serviceId=${selectedUserServiceVariant?.serviceId}&timeSlotId=${search.selectedTimeSlotId}&locationId=${selectedFields.selectedLocationId}&variantId=${selectedUserServiceVariant?.id}&unix=${timeslot?.unixTimestamp}`;
  };

  useEffect(() => {
    if (search.selectedAvailability && search.selectedTimeSlotId) {
      prepareData();
      onOpen();
    } else {
      closeModal();
    }
  }, [
    search.selectedAvailability,
    tenantData.publicServices,
    tenantData.publicTenantConfig?.widget,
  ]);

  useEffect(() => {
    const timeslot = search.selectedAvailability?.availabilityUnix.find(
      (u) => u.id === search.selectedTimeSlotId
    );
    if (timeslot) {
      if (timeslot.locationIds.length === 1) {
        setSelectedFields({
          selectedUserServiceVariantId: widgetUserVariantId || "",
          selectedLocationId: widgetLocationId || timeslot.locationIds[0],
          selectedDuration: null,
        });

        setModalState({
          ...modalState,
          availableLocations: tenantData.publicLocations.filter((l) =>
            timeslot.locationIds.includes(l.id)
          ),
        });
      } else {
        setSelectedFields({
          selectedUserServiceVariantId: widgetUserVariantId || "",
          selectedLocationId: widgetLocationId || "",
          selectedDuration: null,
        });

        setModalState({
          ...modalState,
          availableLocations: tenantData.publicLocations.filter((l) =>
            timeslot.locationIds.includes(l.id)
          ),
        });
      }
    } else {
      setSelectedFields({
        selectedUserServiceVariantId: widgetUserVariantId || "",
        selectedLocationId: widgetLocationId || "",
        selectedDuration: null,
      });
    }
  }, [search.selectedTimeSlotId]);

  useEffect(() => {
    setErrors({
      selectedUserServiceVariantId:
        !selectedFields.selectedUserServiceVariantId,
      selectedLocationId: !selectedFields.selectedLocationId,
    });
  }, [selectedFields]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent
          paddingY={15}
          paddingX={30}
          width={{
            xs: "calc(100% - 10)",
            sm: "calc(100% - 10)",
            md: "600px",
            lg: "600px",
          }}
        >
          <StyledModalHeader textAlign={"center"}>
            {t("Book an appointment")}
          </StyledModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={2} align="stretch">
              {!widgetUserVariantId && (
                <>
                  <StyledText>{t("Service")}</StyledText>
                  <SelectComponent
                    placeholder={t("Select service")}
                    options={availableServices}
                    selectedValueId={
                      selectedFields.selectedUserServiceVariantId
                    }
                    onSelectChange={(e) =>
                      setSelectedFields({
                        ...selectedFields,
                        selectedUserServiceVariantId: e,
                        selectedDuration:
                          availableServices.find((s) => s.id === e)?.duration ||
                          null,
                      })
                    }
                  />
                  {errors.selectedUserServiceVariantId && (
                    <StyledTextError>
                      {t("Please select a service")}
                    </StyledTextError>
                  )}
                </>
              )}
              <StyledText>{t("Location")}</StyledText>
              <SelectComponent
                placeholder={t("Select location")}
                disabled={widgetLocationId ? true : false}
                options={modalState.availableLocations}
                selectedValueId={selectedFields.selectedLocationId}
                onSelectChange={(e) =>
                  setSelectedFields({
                    ...selectedFields,
                    selectedLocationId: e,
                  })
                }
              />
              {errors.selectedLocationId && (
                <StyledTextError>
                  {t("Please select a location")}
                </StyledTextError>
              )}
            </VStack>
            <StyledText marginTop={"10px"}>{t("Select timeslot")}</StyledText>
            {search.selectedAvailability ? (
              <StyledBox overflowY={"scroll"}>
                <SwiperComponentUnix
                  publicAvailability={search.selectedAvailability}
                  buttonColor={
                    tenantData?.publicTenantConfig?.widget?.buttonColor
                  }
                  selectedLocationId={selectedFields.selectedLocationId}
                  fontColor={tenantData?.publicTenantConfig?.widget?.fontColor}
                  minimumHoursBeforeBooking={
                    tenantData?.publicTenantConfig?.widget
                      ?.minimumHoursBeforeBooking
                  }
                  serviceDuration={selectedFields.selectedDuration}
                  defaultSelectedTimeslot={search.selectedTimeSlotId}
                  maxDays={
                    tenantData?.publicTenantConfig?.widget?.maxDays || 14
                  }
                  visibleDays={4}
                ></SwiperComponentUnix>
              </StyledBox>
            ) : null}
            {!widgetServiceId && (
              <StyledText fontSize={10}>
                {t(
                  "In the next step you will be asked to provide your personal details to proceed with booking"
                )}{" "}
                <strong>
                  {t("Payment is required to book the appointment")}
                </strong>
              </StyledText>
            )}
          </ModalBody>

          <ModalFooter
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <Button variant="ghost" onClick={closeModal}>
              {t("Cancel")}
            </Button>
            <StyledButton
              fontColor={tenantData.publicTenantConfig?.widget.fontColor}
              buttonColor={tenantData.publicTenantConfig?.widget.buttonColor}
              mr={3}
              onClick={onNext}
            >
              {t("Next")}
            </StyledButton>
          </ModalFooter>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              fontSize="10px"
              textAlign="left"
              display="flex"
              alignItems="left"
              gap="5px"
            >
              Hand-crafted by
              <a
                href="https://theraplo.com"
                style={{ display: "flex", alignItems: "left" }}
              >
                <Image
                  src="https://theraplo.com/wp-content/uploads/2023/09/download-1-1024x286.png"
                  height="20px"
                  alt="Theraplo Logo"
                />
              </a>
            </Text>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
