import {
  Box,
  ChakraProvider,
  Image,
  StackDivider,
  Text,
  theme,
  VStack,
} from "@chakra-ui/react";
import { ModalComponent } from "./components/ModalComponent";
import { SearchProvider } from "./context/SearchContext";
import { TenantDataProvider } from "./context/TenantDataContext";
import { SelectGrid } from "./grids/SelectGrid";
import { UserGrid } from "./grids/UserGrid";

export const App = ({
  isSmall,
  widgetUserId,
  widgetLocationId,
  widgetServiceId,
  widgetUserVariantId,
  calendarOnly,
}: {
  widgetUserId: string | null;
  widgetLocationId: string | null;
  widgetServiceId: string | null;
  widgetUserVariantId: string | null;
  isSmall: boolean;
  calendarOnly: boolean;
}) => (
  <ChakraProvider theme={theme}>
    <TenantDataProvider
      widgetUserId={widgetUserId}
      widgetLocationId={widgetLocationId}
      widgetServiceId={widgetServiceId}
      widgetUserVariantId={widgetUserVariantId}
      isSmall={isSmall}
      calendarOnly={calendarOnly}
    >
      <SearchProvider>
        {!isSmall && !calendarOnly && (
          <Box
            textAlign="center"
            fontSize="xl"
            p={5}
            maxWidth={{ md: "600px", lg: "800px" }}
          >
            <SelectGrid />
          </Box>
        )}
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={4}
          align="stretch"
          maxWidth={isSmall || calendarOnly ? {} : { md: "500px", lg: "800px" }}
        >
          <UserGrid />
        </VStack>
        <Box
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontSize="10px"
            textAlign="left"
            display="flex"
            alignItems="left"
            gap="5px"
          >
            Hand-crafted by
            <a
              href="https://theraplo.com"
              style={{ display: "flex", alignItems: "left" }}
            >
              <Image
                src="https://theraplo.com/wp-content/uploads/2023/09/download-1-1024x286.png"
                height="20px"
                alt="Theraplo Logo"
              />
            </a>
          </Text>
        </Box>
        <ModalComponent />
      </SearchProvider>
    </TenantDataProvider>
  </ChakraProvider>
);
