// firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { collection, doc, getFirestore, onSnapshot } from "firebase/firestore";

const firebaseConfig = (window as any).fbCnfThrpl || {
  apiKey: "AIzaSyBbNzFHj4sgw3swcFgthOFLO_piYqqILEU",
  authDomain: "dobrostan-terapia.firebaseapp.com",
  projectId: "dobrostan-terapia",
  storageBucket: "dobrostan-terapia.firebasestorage.app",
  messagingSenderId: "366399424958",
  appId: "1:366399424958:web:a01439cb54e227b4add1ef",
  measurementId: "G-VZ08YJ0DTP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { collection, db, doc, onSnapshot };
