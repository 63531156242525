import moment from "moment";
import type { PublicAvailability } from "practicare/types/publicAvailability.model";

export const dateStrings: string[] = [];
for (let i = 0; i <= 14; i++) {
  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() + i);
  const dateString = nextDate.toISOString().slice(0, 10);
  dateStrings.push(dateString);
}
export const findFirstAvailableSlot = (
  publicAvailability: PublicAvailability,
  minimumHoursBeforeBooking?: number
) => {
  const timeRestriction = new Date(
    new Date().getTime() + (minimumHoursBeforeBooking || 24) * 60 * 60 * 1000
  );
  const dates = dateStrings;
  // Iterate over each date in dateStrings
  for (const date of dates) {
    const times = publicAvailability.availability[date];
    if (times) {
      // Get sorted list of times
      const timesAdjusted = Object.keys(times).sort((a, b) => {
        // Direct string comparison works for time in "HH:MM" format
        const [aHours, aMinutes] = a.split(":").map(Number);
        const [bHours, bMinutes] = b.split(":").map(Number);
        return aHours * 60 + aMinutes - (bHours * 60 + bMinutes);
      });
      // Check each time for availability
      for (const time of timesAdjusted) {
        if (times[time] && times[time].id) {
          const [hours, minutes] = time.split(":").map(Number);
          // Pad hours and minutes with leading zero if needed
          const paddedHours = hours.toString().padStart(2, "0");
          const paddedMinutes = minutes.toString().padStart(2, "0");
          const paddedDate = new Date(
            `${date}T${paddedHours}:${paddedMinutes}`
          );
          const isWithin12Hours = paddedDate >= timeRestriction;
          if (isWithin12Hours) {
            return paddedDate;
          }
        }
      }
    }
  }
  return moment("2100-01-01").toDate();
};

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const getLabel = (date: Date) => {
  const today = new Date();
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    return "Today";
  }

  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate() + 1
  ) {
    return "Tomorrow";
  }

  return daysOfWeek[date.getDay()];
};

export const getFormat = (formattedDate: Date) => {
  return formattedDate
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .slice(0, 2)
    .join(".");
};

export const formatCurrency = (value: number, currency: string) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency,
  }).format(value);
